/* eslint-disable jsx-a11y/alt-text */
import { ImgHTMLAttributes, useState } from 'react';

const srcFallbackImage = "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-15.png"

interface PropsImage extends ImgHTMLAttributes<HTMLImageElement> {
    fallback?: string; // pode se transformar num componente de fallback dps...
}
export function Image({ src, alt, fallback = srcFallbackImage, ...props }: PropsImage) {
    const [imgSrc, setImgSrc] = useState(src ? src : fallback);

    const handleError = () => {
        setImgSrc(fallback);
    };

    return (
        <img
            src={imgSrc}
            alt={alt}
            onError={handleError}
            {...props}
        />
    );
}