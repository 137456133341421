import { useCallback, useEffect } from 'react';
import { interfaceLogin } from 'src/dtos/loginDTO';
import { useStorage } from 'src/global/hooks/useStorage';
import { useToast } from 'src/global/toast/useToast';
import { useFormatter } from 'src/global/utils/useFormatter';
import { useIpPublic } from 'src/global/utils/useIpPublic';
import { useProvider } from 'src/provider/provider';
import { getQuestionariosInfo, loginService } from 'src/services/services';

export type UseLoginProps = {
  inputDate: any;
  inputCpf: any;
  setValue: any;
  declaracaoIdLink: any;
};
export const useLogin = ({
  inputCpf,
  inputDate,
  setValue,
  declaracaoIdLink,
}: UseLoginProps) => {
  const { createStorage, removeStorage, selectStorage } = useStorage();
  const { ipPublic, setDeclaracaoId, setQuestionariosInfo } = useProvider();
  const { normalizeCpfNumber, normalizeInputDate, convertDate } =
    useFormatter();
  const { consultIp } = useIpPublic();
  const { toastWarn } = useToast();

  useEffect(() => {
    setValue('inputCpf', normalizeCpfNumber(inputCpf));
  }, [inputCpf, setValue, normalizeCpfNumber]);

  useEffect(() => {
    setValue('inputDate', normalizeInputDate(inputDate));
  }, [inputDate, setValue, normalizeInputDate]);

  useEffect(() => {
    if (declaracaoIdLink) {
      // setDeclaracaoId(atob(declaracaoIdLink ? declaracaoIdLink : 'MjI4MTUx'));
      setDeclaracaoId(declaracaoIdLink);
    } else {
      toastWarn(
        'Link quebrado, não foi encontrado o identificador da declaração'
      );
    }
  }, [declaracaoIdLink, toastWarn, setDeclaracaoId]);

  useEffect(() => {
    consultIp();
  }, []);
  const login = useCallback(
    async (input: Omit<interfaceLogin, 'ip'>) => {
      removeStorage('authToken');
      removeStorage('questionariosInfo');
      const rest = await selectStorage('IMG');
      console.log({ rest });
      const response = await loginService(
        {
          ...input,
          dtNascimento: convertDate(input.dtNascimento),
          cpf: input.cpf.replace(/[\D]/g, ''),
          ip: ipPublic,
        },
        rest!
      );
      createStorage('authToken', JSON.stringify(response.data.data.token));

      const questionarioInfo = await getQuestionariosInfo(
        input.declaracaoSaudeId,
        rest!
      );
      setQuestionariosInfo(questionarioInfo.data);

      return response;
    },
    [ipPublic, createStorage, removeStorage]
  );
  return {
    login,
  };
};
