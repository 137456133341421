import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStorage } from 'src/global/hooks/useStorage';
import { useProvider } from 'src/provider/provider';
import { aceitarTermosUso } from 'src/services/services';
import { useToast } from '../../global/toast/useToast';

export function useLetterBeneficiary() {
  const { selectStorage } = useStorage();
  const [checked, setChecked] = useState(false);
  const { toastErro } = useToast();
  const { ipPublic, declaracaoId, setLoadingValue } = useProvider();
  const navigate = useNavigate();

  const directRouter = '/RegistrarBeneficiary';

  const handleCheckboxChange = (event: any) => {
    setChecked(event.target.checked);
  };

  // colcoar - LetterBeneficiary
  const ConfirmTerm = useCallback(async () => {
    setLoadingValue(true);
    const rest = await selectStorage('IMG');
    console.log({ rest });
    aceitarTermosUso(ipPublic, 3, declaracaoId, rest!)
      .then(() => {
        navigate(directRouter);
      })
      .catch((erro: any) => {
        console.log(erro);
        toastErro(
          `Ocorreu algum erro, entre em contato com administrador. Erro: ${erro?.response?.data?.errors?.message} `
        );
      })
      .finally(() => {
        setLoadingValue(false);
      });
  }, [ipPublic, declaracaoId, navigate, toastErro, setLoadingValue]);

  // ReviewDetails
  const ConfirmTermDataList = useCallback(async () => {
    setLoadingValue(true);
    const rest = await selectStorage('IMG');
    console.log({ rest });
    aceitarTermosUso(ipPublic, 2, declaracaoId, rest!)
      .then(() => {})
      .catch((erro: any) => {
        console.log(erro);
        toastErro(
          `Ocorreu algum erro, entre em contato com administrador. Erro: ${erro?.response?.data?.errors?.message} `
        );
      })
      .finally(() => {
        setLoadingValue(false);
      });
  }, [ipPublic, declaracaoId, navigate, toastErro, setLoadingValue]);

  return {
    checked,
    setChecked,
    handleCheckboxChange,
    ConfirmTerm,
    ConfirmTermDataList,
  };
}
