import { Checkbox } from '@mui/material';
import Button from '@mui/material/Button';
import { useCallback, useEffect, useState } from 'react';
import { useStorage } from 'src/global/hooks/useStorage';
import { useProvider } from 'src/provider/provider';
import { StepForm } from '../../components/stepForm';
import { useTermUsers } from './useTermUser';

export function TermsUse() {
  const { ConfirmTerm, checked, handleCheckboxChange } = useTermUsers();
  const { controlModal, setModalControl } = useProvider();
  const { selectStorage } = useStorage();
  const [operationState, setOperationState] = useState('');
  const [operationAxil, setOperationAxil] = useState<any>({});

  const operation = useCallback(async () => {
    const data = await selectStorage('IMG');
    setOperationState(data!);
    selectOperation(data!);
    // setControlModal(true);
  }, []);

  useEffect(() => {
    operation();
  }, []);

  const operationData = [
    {
      name: 'HUB HEALTH ADMINISTRADORA Digital',
      cnpj: '46.316.638/0001-37',
      endereco:
        ' na Avenida Rio Branco, 0026, SBL, CENTRO, RIO DE JANEIRO - RJ',
      email: 'hubheathrecife.com.br',
    },
    {
      name: 'PERSONA ADMINISTRADORA DE PLANO DE SAUDE LTDA',
      cnpj: '21.164.436/0001-96',
      endereco: ' Avenida Raja Gabaglia, 3502 - Estoril - BELO HORIZONTE, MG',
      email: 'contato@admpersona.com.br',
    },
    {
      name: 'FOX SAÚDE ASSISTÊNCIA MÉDICA INTEGRADA LTDA',
      cnpj: '09.376.798/0001-45',
      endereco:
        'AV AYRTON SENNA DA SILVA 1221 SALA 17 1º ANDAR PIEDADE – JABOATÃO DOS GUARARAPES – PE',
      email: 'ADMINISTRATIVO@ASSBRACOM.ORG.BR',
    },
  ];

  const selectOperation = (index: string) => {
    switch (index) {
      case '1':
        setOperationAxil(operationData[0]);
        break;
      case '2':
        setOperationAxil(operationData[1]);
        break;
      case '3':
        setOperationAxil(operationData[2]);
        break;
      default:
        setOperationAxil({});
    }
  };

  return (
    <div className="w-screen h-screen p-10 ">
      <StepForm itemSelect={0} />

      <div className="text-center mb-2 italic">Termo de Uso</div>
      <div className="shadow p-5 h-3/4 overflow-y-auto">
        <p className="mt-5 text-justify ">
          Termos de Uso e Política de Privacidade Plataforma
          {'  ' + operationAxil?.name}
        </p>
        <p className="mt-5 text-justify text-sm italic font-extralight">
          A Plataforma {'  ' + operationAxil?.name}, disponibilizada pela{' '}
          {'  ' + operationAxil?.name}, inscrita no CNPJ sob n.º{' '}
          {operationAxil?.cnpj}, com sede na {'  ' + operationAxil?.endereco}
          (“Provedora”) tem como objetivo viabilizar a contratação de plano de
          saúde por meio totalmente eletrônico, excluindo a necessidade de
          preenchimento de dados em papel, destinando-se a você, consumidor
          (“Usuário”), para validação e fornecimento das informações necessárias
          para tanto. Para acesso à Plataforma e utilização de suas
          funcionalidades, o Usuário deverá ler atenta e integralmente o
          presente Termo e, se estiver de acordo com seu conteúdo, deverá se
          manifestar neste sentido quando de seu primeiro acesso à Plataforma,
          ao selecionar a opção “Aceito os Termos” quando de seu primeiro
          acesso, demonstrando seu consentimento livre, expresso e informado com
          relação ao conteúdo deste documento. Caso o Usuário discorde de alguma
          das previsões destes Termos, não deverá utilizar a Plataforma.
          Ademais, ao aceitar os presentes Termos, o Usuário declara ter
          previamente contatado corretor (“Corretor”) a fim de contratar plano
          de saúde, e ter fornecido, também mediante consentimento livre,
          expresso e informado, informações e dados a este para preenchimento de
          proposta (“Proposta”), tendo o Corretor sido expressamente autorizado
          a utilizá-las para tais fins.
        </p>

        <p className="mt-5 text-justify">
          1. Como posso utilizar a Plataforma?
        </p>
        <p className="mt-5 text-justify text-sm italic font-extralight">
          Para utilização da Plataforma, o Usuário deverá ser considerado
          civilmente capaz à luz da legislação brasileira ou estar devidamente
          autorizado pelos seus responsáveis legais para tanto. Ainda, deverá
          seguir as instruções fornecidas pela Provedora para acesso e
          autenticação, devendo inserir na Plataforma o validador de acesso
          encaminhado pela Provedora, para verificação dos dados inseridos pelo
          Corretor na Proposta, bem como preenchimento da Declaração Pessoal de
          Saúde. Nesse sentido, fornecidas as credenciais de acesso ao Usuário,
          este deverá verificar as informações inseridas pelo Corretor para
          envio da Proposta, podendo inclusive alterá-las, caso estejam
          incorretas, incompletas ou desatualizadas. Assim, o Usuário reconhece,
          ao aceitar os presentes Termos, ter sido previamente informado acerca
          deste procedimento pelo Corretor e estar de acordo com o processo em
          questão. Ademais, poderão ser solicitadas ao Usuário informações
          complementares quando de seu acesso à Plataforma, as quais deverão ser
          fornecidas pelo Usuário para finalização da Proposta e envio para
          análise pela Provedora, tendo como base a legislação específica
          aplicável à contratação de planos de saúde. Nesse sentido, o Usuário
          deverá, ainda, preencher a Declaração Pessoal de Saúde por meio da
          Plataforma, ciente de que este procedimento se encontra previsto nas
          normativas aplicáveis à contratação de planos de saúde. Ainda, para
          tanto, poderá o Usuário preenchê-la na presença de um médico
          orientador, hipótese na qual deverá selecionar o checkbox
          correspondente a esta opção. Assim, quando do aceite aos presentes
          Termos, o Usuário reconhece e autoriza que a Provedora poderá utilizar
          seus dados de contato, como telefone e e-mail, para validação das
          informações fornecidas, inclusive mediante envio de token para
          inclusão na Plataforma. O usuário compreende que, ao encaminhar este
          documento por meio desta plataforma, assina-o eletronicamente, tendo
          esta assinatura valor jurídico, equivalendo à assinatura manuscrita,
          conforme previsão do artigo 10, parágrafo 2º, da Medida Provisória
          2.200-2/2001, para todos os fins legais. Ainda, por meio do aceite aos
          presentes Termos, o Usuário se declara ciente de que é o único e
          exclusivo responsável por todas as informações fornecidas quando de
          seu acesso à Plataforma, responsabilizando-se, inclusive, perante
          terceiros, por quaisquer danos ou prejuízos decorrentes de informações
          incorretas, incompletas ou inverídicas inseridas neste. Ainda, o
          Usuário reconhece que a inclusão de qualquer informação inverídica,
          falsa ou adulterada o sujeitará a todas as sanções aplicáveis
          constantes da legislação brasileira, inclusive em âmbito criminal.
        </p>
        <p className="mt-5 text-justify">
          2. O que preciso saber para utilizar a Plataforma?
        </p>
        <p className="mt-5 text-justify text-sm italic font-extralight">
          Por meio do aceite aos presentes Termos, o Usuário declara: (i) estar
          ciente de que é vedada a inserção de qualquer conteúdo ilícito,
          inverídico, falso ou capaz de violar direitos das partes ou de
          terceiros na Plataforma; (ii) ter conhecimento de que não deverá
          utilizar quaisquer dados obtidos em razão da utilização da Plataforma,
          para quaisquer outros fins diversos daqueles previstos nestes Termos;
          (iii) reconhecer que não deverá inserir na Plataforma quaisquer
          elementos nocivos, capazes de causar danos à Provedora e a terceiros;
          e (iv) estar ciente de que não deverá compartilhar suas credenciais de
          acesso com terceiros, sendo o único responsável por quaisquer
          operações, realizadas por meio de sua conta. Por meio do aceite aos
          presentes Termos, o Usuário reconhece que, em qualquer hipótese, é o
          único responsável pela utilização da Plataforma, isentando a Provedora
          de qualquer responsabilidade por danos e prejuízos decorrentes do uso
          indevido deste.
        </p>

        <p className="mt-5 text-justify">3. Propriedade Intelectual</p>
        <p className="mt-5 text-justify text-sm italic font-extralight">
          Os programas de computador, arquivos, textos, ícones, desenhos,
          vídeos, sons, marcas, logotipos, layouts, templates, inseridos na
          Plataforma são protegidos por direitos de propriedade intelectual, da
          Provedora ou de terceiros que autorizaram sua utilização. Sendo assim,
          estão protegidos pelas leis e pelos tratados internacionais, sendo
          vedada sua cópia, alteração, reprodução, ou qualquer outro tipo de
          utilização, ficando os infratores sujeitos às sanções civis e
          criminais correspondentes, nos termos das Leis 9.279/96, 9.610/98 e
          9.609/98. Qualquer violação aos direitos de Propriedade Intelectual
          poderá culminar, além da incidência das responsabilidades legais, no
          bloqueio sumário e sem aviso prévio do Usuário, ocasião em que nada
          será devido a este. Ademais, as marcas, os nomes comerciais ou
          logotipos de qualquer espécie apresentados por meio da Plataforma são
          de propriedade da Provedora ou de terceiro que permitiu o seu uso, de
          modo que a utilização da Plataforma não consiste em autorização para
          que o Usuário possa citar as tais marcas, nomes comerciais e
          logotipos.
        </p>
        <p className="mt-5 text-justify">4. Isenções de Responsabilidade</p>
        <p className="mt-5 text-justify text-sm italic font-extralight">
          A Provedora não se responsabiliza: (i) por quaisquer
          indisponibilidades, erros ou falhas apresentados pela Plataforma; (ii)
          por erros ou eventuais inconsistências na transmissão de dados, pela
          qualidade ou disponibilidade da conexão de Internet, que impeçam o
          adequado recebimento de informações pela Provedora ou pelo Usuário;
          (iii) pela apresentação de dados desatualizados, incompletos ou
          inverídicos atualizados por Corretores ou terceiros; (iv) pela
          presença de vírus ou de elementos nocivos nos dispositivos que são
          utilizados para acessar a Plataforma; e (v) pelos danos e prejuízos de
          toda natureza decorrentes do conhecimento que terceiros não
          autorizados possam ter de quaisquer dados fornecidas pela Provedora,
          em decorrência de falha exclusivamente relacionada ao Usuário ou a
          terceiros que fujam a qualquer controle razoável da Provedora.
        </p>

        <p className="mt-5 text-justify">5. Privacidade</p>
        <p className="mt-5 text-justify text-sm italic font-extralight">
          Por meio da Plataforma, a Provedora coleta, trata e armazena dados
          pessoais e informações inseridas ativamente pelo Usuário para a
          contratação de plano de saúde no modo online. A Plataforma poderá
          coletar, também, algumas informações geradas automaticamente, tais
          como características do dispositivo de acesso, do navegador, IP de
          acesso com data e hora, origem do IP, informações sobre cliques,
          páginas acessadas dentro e fora da Plataforma, identificador do
          dispositivo móvel, área geográfica, URL de origem e informações sobre
          as ações realizadas ou interações com os nossos recursos digitais.
          Ainda, poderão ser utilizadas algumas tecnologias de natureza padrão
          para coletar as informações do Usuário, especialmente cookies, de modo
          a melhorar sua experiência de navegação. As informações cadastradas e
          coletadas são utilizadas para a devida prestação de serviços pela
          Provedora e para envio de Proposta, bem como para fins publicitários e
          promocionais de planos de saúde adequados à suas necessidades, para
          fins estatísticos e para aperfeiçoar a experiência de navegação do
          Usuário, com o que, desde já, o Usuário manifesta seu consentimento
          livre, expresso e informado. As informações coletadas para essas
          finalidades poderão incluir os seus dados de contato (por exemplo,
          nome, endereço postal, endereço de e-mail, número de telefone etc.),
          dados de identificação eletrônica (por exemplo, nome de usuário,
          senha, perguntas de segurança, endereço IP etc.). O Usuário pode a
          qualquer momento impedir o uso destas tecnologias para coleta
          automática de dados. Entretanto, ressaltamos que, caso essa nova
          configuração seja implementada, algumas das funções oferecidas pela
          Plataforma podem deixar de funcionar corretamente, sendo necessária a
          habilitação das configurações. Ainda, o Usuário reconhece, ao aceitar
          estes Termos, que forneceu ao Corretor consentimento livre, expresso e
          informado para uso das informações a este fornecidas para inserção na
          Plataforma e preenchimento da Proposta, ciente de que estas
          informações estão sujeitas, inclusive, às previsões destes Termos
          quando de seu aceite. A Provedora considera todas as informações
          coletadas por meio da Plataforma confidenciais, garantindo que essas
          serão tratadas e armazenadas conforme as determinações dos presentes
          Termos e de acordo com as normas aplicáveis, especialmente a Lei Geral
          de Proteção de Dados e o Marco Civil da Internet. O Usuário se declara
          ciente de que a Provedora poderá compartilhar os dados coletados por
          meio da Plataforma com terceiros, nas seguintes situações: (i) se
          necessário às atividades comerciais e aos serviços prestados pela
          Provedora; (ii) com os Corretores, para envio e eventual
          complementação e/ou alteração da Proposta, hipótese na qual o Usuário
          deverá validar a operação; (iii) com outras empresas parceiras para
          fins estatísticos e para a prestação dos serviços relacionados às
          funcionalidades disponibilizadas pela Plataforma (e.g., hospitais);
          (iv) para proteger os interesses da Provedora, em qualquer conflito,
          incluindo demandas judiciais; (v) no caso de transações e alterações
          societárias envolvendo a Provedora, hipótese em que a transferência de
          informações será necessária para a continuidade dos serviços e entrega
          de produtos; ou (vi) mediante ordem judicial ou por requerimento de
          autoridades administrativas que detenham competência legal para sua
          requisição. Os dados coletados pela Provedora por meio da Plataforma
          serão armazenados em servidores próprios ou por ela contratados,
          enquanto o tratamento dos dados e as finalidades forem necessárias e
          até o cumprimento dos prazos previstos em lei, sendo adotados os
          esforços razoáveis de mercado para garantir a segurança de seus
          sistemas na guarda de tais informações, entre eles as diretrizes sobre
          padrões de segurança estabelecidas no art. 13, do Decreto n°
          8.771/2016. O Usuário poderá requerer a exclusão dos dados coletados
          pela Provedora a seu respeito, bem como o exercício de quaisquer dos
          seus direitos enquanto titular dos dados pessoais pelo e-mail
          contato@hubcarerecife.com.br. A Provedora empreenderá os melhores
          esforços para atender a todos os pedidos, inclusive o de exclusão no
          menor tempo possível, ressaltando, contudo, que tal exclusão
          acarretará também na exclusão do cadastro do Usuário na Plataforma, o
          que impedirá a aquisição de novos produtos por meio deste. Ainda,
          mesmo em caso de requisição de exclusão, a Provedora respeitará o
          prazo de armazenamento mínimo de informações determinado pela
          legislação brasileira. Ao aceitar os presentes Termos, o Usuário
          manifesta seu consentimento livre, expresso e informado com relação
          aos mecanismos de guarda, armazenamento, compartilhamento e tratamento
          das informações e dados coletados pela Provedora quando do cadastro,
          acesso e utilização da Plataforma.
        </p>

        <p className="mt-5 text-justify">6. Disponibilidade da Plataforma</p>
        <p className="mt-5 text-justify text-sm italic font-extralight">
          A Plataforma é disponibilizada por prazo indeterminado, podendo a
          Provedora, a qualquer tempo, descontinuar o serviço, sem necessidade
          de prévio aviso, não sendo devida qualquer indenização ao Usuário em
          razão disso, comprometendo-se, entretanto, a cumprir quaisquer
          obrigações previamente assumidas por meio da Plataforma.
        </p>

        <p className="mt-5 text-justify">7. Disposições Gerais</p>
        <p className="mt-5 text-justify text-sm italic font-extralight">
          A Plataforma encontra-se sujeita a contínuo aprimoramento, bem como os
          presentes Termos, na medida em que refletem suas funcionalidades.
          Nesse sentido, este documento poderá ser modificado a qualquer
          momento, desde que não haja vedação legal nesse sentido. Para saber se
          esta é a versão mais atualizada, verifique a data indicada ao término
          do documento. Se o Usuário discordar de quaisquer modificações, deverá
          se abster de utilizar a Plataforma. Os casos fortuitos ou de força
          maior serão excludentes de responsabilidades das partes, na forma da
          Legislação Brasileira. Ademais, se qualquer parte destes Termos for
          considerada inválida ou inexequível, tal trecho deve ser interpretado
          de forma consistente com a lei aplicável, para refletir, na medida do
          possível, a intenção original das partes, sendo que as demais
          disposições permanecerão em pleno vigor e efeito. Ainda, eventual
          falha ou opção da Provedora em não exigir quaisquer direitos ou
          disposições dos presentes Termos não constituirá renúncia ou novação,
          tratando-se de mera liberalidade, podendo esta exercer regularmente o
          seu direito, dentro dos prazos legais.
        </p>

        <p className="mt-5 text-justify">8. Legislação e Foro</p>
        <p className="mt-5 text-justify text-sm italic font-extralight">
          Esses Termos serão regidos, interpretados e executados de acordo com
          as leis da República Federativa do Brasil, independentemente dos
          conflitos dessas leis com leis de outros estados ou países, sendo
          competente o Foro do local de residência do Usuário, no Brasil, para
          dirimir qualquer dúvida decorrente deste instrumento. O Usuário
          consente, expressamente, com a competência desse juízo, e renuncia,
          neste ato, à competência de qualquer outro foro, por mais privilegiado
          que seja ou venha a ser. Os presentes Termos estão disponíveis para
          leitura a qualquer momento por meio do link:{' '}
          {'  ' + operationAxil?.email}
        </p>

        <p className="mt-5 text-justify">
          Última atualização: 27/04/2022 – 14:11hs.
        </p>
      </div>
      <div className="flex flex-row items-center mt-3">
        <Checkbox onChange={handleCheckboxChange} />
        <div> Estou ciente.</div>
      </div>
      <Button
        variant="contained"
        fullWidth
        disabled={!checked}
        onClick={() => ConfirmTerm()}
      >
        Seguir
      </Button>
    </div>
  );
}
