import { AxiosResponse } from 'axios';
import { BaseResponseDTO } from 'src/dtos/baseResponseDTO';
import { interfaceLogin } from 'src/dtos/loginDTO';
import { LoginResponseDTO } from 'src/dtos/loginResponseDTO';
import { PropostaInfoDetailsResponseDTO } from 'src/dtos/propostaInfoDetailsResponseDTO';
import { QuestaoQuestionarioResponse } from 'src/dtos/questaoQuestionarioResponse';
import { QuestionariosInfoResponse } from 'src/dtos/questionariosInfoResponse';
import { apiDeclaracaoSaude } from './api';

// end-points
const URL_DS = 'api/v1/declaracao-saude';
const URL_TERM_USER = 'api/v1/declaracao-saude/adicionar-log-atividade';

export const loginService = async (dto: interfaceLogin, operation: string) => {
  const response = await apiDeclaracaoSaude.post<
    interfaceLogin,
    AxiosResponse<LoginResponseDTO>
  >(`c${operation}/${URL_DS}/login`, { ...dto });

  apiDeclaracaoSaude.defaults.headers.common.Authorization =
    'Bearer ' + response.data.data.token;

  return response;
};

export async function getPropostaInfoDetails(
  declaracaoId: string,
  operation: string
): Promise<PropostaInfoDetailsResponseDTO> {
  const response = await apiDeclaracaoSaude.get<
    any,
    AxiosResponse<BaseResponseDTO<PropostaInfoDetailsResponseDTO>>
  >(`c${operation}/${URL_DS}/${declaracaoId}`, {
    headers: {
      Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('authToken')!),
    },
  });

  return response.data.data;
}

export const aceitarTermosUso = async (
  ip: string,
  step: number,
  declaracaoSaudeId: string,
  operation: string
) => {
  const link = `c${operation}/`;
  await apiDeclaracaoSaude.post(link + URL_TERM_USER, {
    declaracaoSaudeId: declaracaoSaudeId,
    passoId: step,
    ip,
  });
};

export const getQuestionariosInfo = async (
  declaracaoId: string,
  operation: string
) => {
  const response = await apiDeclaracaoSaude.get<
    any,
    AxiosResponse<QuestionariosInfoResponse>
  >(`c${operation}/${URL_DS}/${declaracaoId}/questionario-proposta`);
  return response.data;
};

export const getQuestoesQuestionario = async (
  declaracaoId: string,
  questionarioId: string,
  ip: string,
  operation: string
) => {
  const response = await apiDeclaracaoSaude.get<
    any,
    AxiosResponse<BaseResponseDTO<QuestaoQuestionarioResponse[]>>
  >(
    `c${operation}/${URL_DS}/${declaracaoId}/questionario/${questionarioId}?ip=${ip}`
  );
  return response.data.data;
};

export const saveDigitalFiles = async (
  declaracaoId: number,
  questionarioId: number,
  formData: FormData,
  pessoaId: number,
  operationId: string,
  operation: string
) => {
  const response = await apiDeclaracaoSaude.post(
    `c${operation}/${URL_DS}/${declaracaoId}/questionario/${questionarioId}/cliente/${operationId}/upload`,
    formData,

    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response.data.data;
};

export const setQuestionario = async (
  questionarioId: string,
  respostas: any,
  declaracaoId: string,
  ip: string,
  operation: string
) => {
  await apiDeclaracaoSaude.post(
    `c${operation}/api/v1/declaracao-saude/${declaracaoId}/questionario/${questionarioId}`,
    {
      respostas,
      ip,
    }
  );
};

enum UserStep {
  LOGIN, // Logado com sucesso - Recebeu link por email: LIDIANERMOURA01@GMAIL.COM
  CONFIRMOU_TERMOS_USO, // Confirmou a leitura dos termos
  CONFIRMOU_DADOS, // Confirmou os valores e dados cadastrais
  CONFIRMOU_CARTA_ORIENTACAO, // Confirmou a leitura da Carta de Orientação da ANS
  QUESTIONARIO_FINALIZADO, // Declaração salva com sucesso, id: 1251
  ARQUIVOS_ENVIADOS, // Arquivos salvo com sucesso para a declaração
}

const mockData = {
  status: 'OK',
  statusCode: 200,
  declaracoes: [
    {
      tipo: 'arllan pablo',
      lastStep: 'CONFIRMOU_TERMOS_USO',
    },
    {
      tipo: 'AMANDA HELENA SOARES BALBINO',
      lastStep: 'CONFIRMOU_DADOS',
    },
  ],
  data: {
    dadosProposta: {
      clienteId: 32242,
      propostaId: 32593,
      vigencia: '2024-02-01T00:00:00.000Z',
    },
    dadosTitular: {
      nome: 'AMANDA HELENA SOARES BALBINO',
      bairro: 'Ouro Preto',
      celular: '81985195627',
      cep: '53370100',
      cidade: 'Olinda',
      cpf: '11256129402',
      dtNascimento: '1997-01-26T00:00:00.000Z',
      email: 'CLEIDEANDRADE63011@GMAIL.COM',
      endereco: 'Rua Faisão',
      numero: 'SN',
      sexo: 'F',
      telefone: '',
      uf: 'PE',
    },
    financeiro: [
      {
        cpf: '11256129402',
        nome: 'AMANDA HELENA SOARES BALBINO',
        dtNascimento: '1997-01-26T00:00:00.000Z',
        tipo: 'TITULAR',
        plano: 'HAPVIDA VIG 12/2023',
        valor: '295.59',
      },
      {
        dtNascimento: '',
        nome: 'ASSOC',
        cpf: '',
        tipo: 'TAXA_ASSOCIATIVA',
        plano: '',
        valor: 15,
      },
    ],
    totalProposta: 310.59,
  },
};

export const usersStatus = async (dsNumber: string, operation: string) => {
  const response = await apiDeclaracaoSaude.get<
    any,
    AxiosResponse<QuestionariosInfoResponse>
  >(`c${operation}/${URL_DS}/declaracao-saude/${dsNumber}
`);
  return response.data;
};

export function flowUser() {
  // const response = await apiDeclaracaoSaude.post(`/api/v1/declaracao-saude/${DS_NUMBER}`);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockData);
    }, 300);
  });
}
