import { Route, Routes } from 'react-router-dom';
import { Finished } from 'src/pages/Finished';
import { LetterBeneficiary } from '../pages/letterBeneficiary';
import { Login } from '../pages/login';
import { PhotoCapture } from '../pages/photoCapture';
import { RegistrarBeneficiary } from '../pages/registrarBeneficiary/registrarBeneficiary';
import { ReviewDetails } from '../pages/reviewDetails';
import { TermsUse } from '../pages/termsUse';
import PrivateRoute from './privatedRoutes';

export const RoutesApplication = () => {
  return (
    <Routes>
      <Route element={<Login />} path="/" />
      <Route element={<Finished />} path="/Finished" />
      <Route
        element={
          <PrivateRoute>
            <ReviewDetails />
          </PrivateRoute>
        }
        path="/ReviewDetails"
      />
      <Route
        element={
          <PrivateRoute>
            <TermsUse />
          </PrivateRoute>
        }
        path="/TermsUse"
      />
      <Route
        element={
          <PrivateRoute>
            <LetterBeneficiary />
          </PrivateRoute>
        }
        path="/LetterBeneficiary"
      />
      <Route
        element={
          <PrivateRoute>
            <RegistrarBeneficiary />
          </PrivateRoute>
        }
        path="/RegistrarBeneficiary"
      />
      <Route
        element={
          <PrivateRoute>
            <PhotoCapture />
          </PrivateRoute>
        }
        path="/PhotoCapture"
      />
    </Routes>
  );
};
