enum modeEmun {
  homo = 1,
  prod = 2,
}

const mode: modeEmun = 2;
export function modeApplication() {
  return mode === modeEmun.prod
    ? process.env.REACT_APP_API_PRODUCTION
    : process.env.REACT_APP_API_HOMOLOG;
}

export const decodeToken = (token: string) => {
  const payload = token.split('.')[1];
  const decodedPayload = atob(payload);
  return JSON.parse(decodedPayload);
};
