import { useState } from 'react';

export const PermissionCam = () => {
  const [statusCam, setStatusCam] = useState(false);
  async function checkCameraPermission(menssageActive: boolean) {
    try {
      // Verificar o status da permissão da câmera
      const permission = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      });

      if (permission?.state === 'granted') {
        // Permissão já foi concedida
        console.log('Acesso à câmera permitido.');
        // Aqui você pode iniciar o uso da câmera, por exemplo
        // usando navigator.mediaDevices.getUserMedia
      } else if (permission?.state === 'denied') {
        // Permissão negada, informar o usuário para habilitar manualmente
        getPermissionCam();
        if (menssageActive) {
          alert(
            'A permissão para acessar a câmera foi negada. Por favor, habilite manualmente nas configurações do navegador.'
          );
        }
      } else {
        // Estado de permissão 'prompt', perguntar ao usuário
        getPermissionCam();
        requestCameraAccess();
      }
    } catch (error) {
      console.error('Erro ao verificar a permissão da câmera:', error);
    }
  }

  async function requestCameraAccess() {
    try {
      // Solicitar o acesso à câmera
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      console.log('Permissão concedida e câmera acessada.');
      setStatusCam(true);
      // Aqui você pode manipular o stream da câmera
    } catch (error) {
      console.error('Erro ao solicitar a câmera:', error);
      setStatusCam(false);
      getPermissionCam();
      //   alert('Acesso à câmera bloqueado ou negado pelo usuário.');
    }
  }

  const getPermissionCam = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(function (stream) {
        // Acesso permitido à câmera, faça algo com o stream de vídeo
        console.log('Acesso à câmera concedido');
      })
      .catch(function (err) {
        // Acesso negado ou ocorreu um erro
        console.error('Acesso à câmera negado ou erro:', err);
      });
  };

  return {
    checkCameraPermission,
    getPermissionCam,
    statusCam,
  };
};
