import {
  createContext,
  ReactChild,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { QuestionarioInfo } from 'src/dtos/questionariosInfoResponse';

type ProviderProps = {
  user: any;
  isLoading: boolean;
  ipPublic: any;
  controlModal: any;
  stateRandom: number | any;
  declaracaoId: string;
  randomGrenerated: () => void;
  setDeclaracaoId: (value: any) => void;
  setModalControl: (value: boolean) => void;
  setValueIpPublic: (value: any) => void;
  setUserValue: (value: any) => void;
  setLoadingValue: (value: boolean) => void;
  questionariosInfo: QuestionarioInfo[];
  setQuestionariosInfo(questionarios: QuestionarioInfo[]): void;
};

type children = {
  children?: ReactChild;
};

const GlobalContext = createContext<ProviderProps>({
  user: false,
  isLoading: false,
  ipPublic: false,
  controlModal: false,
  stateRandom: false,
  declaracaoId: '',
  questionariosInfo: [],
  setModalControl: (_val: boolean) => false,
  randomGrenerated: () => false,
  setValueIpPublic: (_val: any) => false,
  setUserValue: (_val: any) => false,
  setLoadingValue: (_val: boolean) => false,
  setDeclaracaoId: (_val: any) => false,
  setQuestionariosInfo: (questionarios: QuestionarioInfo[]) => [],
});

function useManipulation(): ProviderProps {
  const [user, setUser] = useState<any>();
  const [declaracaoId, setDatadeclaracaoId] = useState('');
  const [isLoading, setLoad] = useState(false);
  const [ipPublic, setIpPublic] = useState<any>();
  const [controlModal, setControlModal] = useState(false);
  const [stateRandom, setStateRandom] = useState<number>();
  const [questionariosInfo, setQuestionariosInfoState] = useState<
    QuestionarioInfo[]
  >([]);

  useEffect(() => {
    const cachedDeclaracao = localStorage.getItem('declaracaoId');
    if (cachedDeclaracao) {
      setDatadeclaracaoId(cachedDeclaracao);
    }
    const cachedIp = localStorage.getItem('ip');
    if (cachedDeclaracao) {
      setIpPublic(cachedIp);
    }
    const cachedQuestionariosInfo = localStorage.getItem('questionariosInfo');
    if (cachedQuestionariosInfo) {
      setQuestionariosInfoState(JSON.parse(cachedQuestionariosInfo));
    }
  }, [setDatadeclaracaoId, setIpPublic]);

  const setUserValue = useCallback(
    (val: any) => {
      setUser(val);
    },
    [setUser]
  );

  const setDeclaracaoId = useCallback(
    (val: string) => {
      localStorage.setItem('declaracaoId', val);
      setDatadeclaracaoId(val);
    },
    [setDatadeclaracaoId]
  );

  const setModalControl = (val: boolean) => {
    setControlModal(val);
  };

  const randomGrenerated = () => {
    const random = Math.random();
    console.log('rando gerado: ', random);
    setStateRandom(random);
  };

  const setLoadingValue = useCallback(
    (val: boolean) => {
      setLoad(val);
    },
    [setLoad]
  );

  const setValueIpPublic = useCallback(
    (val: any) => {
      localStorage.setItem('ip', val);
      setIpPublic(val);
    },
    [setIpPublic]
  );

  const setQuestionariosInfo = useCallback(
    (val: QuestionarioInfo[]) => {
      localStorage.setItem('questionariosInfo', JSON.stringify(val));
      setQuestionariosInfoState(val);
    },
    [setQuestionariosInfoState]
  );

  return {
    user,
    isLoading,
    ipPublic,
    questionariosInfo,
    declaracaoId,
    setUserValue,
    setValueIpPublic,
    setLoadingValue,
    setDeclaracaoId,
    setQuestionariosInfo,
    controlModal,
    setModalControl,
    randomGrenerated,
    stateRandom,
  };
}

function useProvider(): ProviderProps {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('Erro');
  }
  return context;
}

const Provide = ({ children }: children) => {
  return (
    <GlobalContext.Provider value={useManipulation()}>
      {children}
    </GlobalContext.Provider>
  );
};

export { Provide, useProvider };
