import { CircularProgress } from '@mui/material';
import { useProvider } from 'src/provider/provider';
export const LoadingOverlay = () => {
  const { isLoading } = useProvider();

  return isLoading ? (
    <div className="fixed inset-0 left-0 flex w-full items-center justify-center bg-black bg-opacity-50 z-50">
      <CircularProgress />
    </div>
  ) : <></>;
};
