import { useCallback } from 'react';
import { toast } from 'react-toastify';

export function useToast() {
  const toastErro = useCallback((message: string) => {
    toast.error(message);
  }, []);

  const toastSuccess = useCallback((message: string) => {
    toast.success(message);
  }, []);

  const toastInfo = useCallback((message: string) => {
    toast.info(message);
  }, []);

  const toastWarn = useCallback((message: string) => {
    toast.warn(message);
  }, []);

  const toastDismissAll = useCallback(() => {
    toast.dismiss();
  }, []);

  return {
    toastErro,
    toastSuccess,
    toastInfo,
    toastWarn,
    toastDismissAll,
  };
}
