import { useCallback } from 'react';
import { useProvider } from '../../provider/provider';
import { api } from '../../services/api';

export const useIpPublic = () => {
  const uri = process.env.REACT_APP_IP_PUBLIC_QUERY!;
  const uriAlternative = process.env.REACT_APP_IP_PUBLIC_QUERY_ALTERNATIVE_1!;
  const { setValueIpPublic, setLoadingValue } = useProvider();

  const consultIp = useCallback(() => {
    if (setLoadingValue && setValueIpPublic && uri) {
      setLoadingValue(true);
      return api
        .get(uri)
        .then((val) => {
          setValueIpPublic(val?.data.ip);
        })
        .catch((erro: any) => {
          api
            .get(uriAlternative)
            .then((res) => setValueIpPublic(res.data.ipString))
            .finally(() => {
              setLoadingValue(false);
            });
        })
        .finally(() => {
          setLoadingValue(false);
        });
    }
  }, [setLoadingValue, setValueIpPublic, uri]);

  return {
    consultIp,
  };
};
