import { useProvider } from 'src/provider/provider';
import { getPropostaInfoDetails } from 'src/services/services';

export const useReviewDetails = () => {
  const { ipPublic } = useProvider();

  const getReviewData = async (dsId: string, operation: string) => {
    if (ipPublic) {
      return getPropostaInfoDetails(dsId, operation);
    }
  };

  return {
    getReviewData,
  };
};
