import { useCallback } from 'react';
import { useStorage } from 'src/global/hooks/useStorage';
import { useProvider } from 'src/provider/provider';
import {
  getQuestoesQuestionario,
  setQuestionario,
} from 'src/services/services';

export const useHealthQuiz = () => {
  const { setLoadingValue, ipPublic, questionariosInfo } = useProvider();
  const { selectStorage } = useStorage();

  const getQuestions = useCallback(
    async (dsId: string, index: number) => {
      setLoadingValue(true);
      if (questionariosInfo) {
        const operation = await selectStorage('IMG');
        const quizId = questionariosInfo[index].questionarioId;

        const questoes = await getQuestoesQuestionario(
          dsId,
          quizId.toString(),
          ipPublic,
          operation!
        );
        setLoadingValue(false);
        return questoes;
      }
      throw new Error('Questionario ID não encontrado.');
    },
    [setLoadingValue, ipPublic, questionariosInfo]
  );

  const setQuestionsAll = useCallback(
    async (
      questionarioId: string,
      respostas: any,
      declaracaoId: string,
      ip: string
    ) => {
      setLoadingValue(true);
      const operation = await selectStorage('IMG');
      return setQuestionario(
        questionarioId,
        respostas,
        declaracaoId,
        ip,
        operation!
      )
        .then((res) => res)
        .finally(() => {
          setLoadingValue(false);
        });
    },
    [setLoadingValue]
  );

  return {
    getQuestions,
    setQuestionsAll,
  };
};
