import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { useNavigate } from 'react-router-dom';
import { useStorage } from 'src/global/hooks/useStorage';
import { Finished } from 'src/pages/Finished';
import { useReviewDetails } from 'src/pages/reviewDetails/useReviewDetails';
import { useProvider } from 'src/provider/provider';
import { getQuestionariosInfo } from 'src/services/services';
import { useToast } from '../../global/toast/useToast';

interface PropsSelectModal {
  activeModal: boolean;
  setActiveModal: () => void;
  mode?: number;
}

const display = {
  display: {
    xs: 'none',
    sm: 'table-cell',
    md: 'table-cell',
    lg: 'table-cell',
    xl: 'table-cell',
  },
};

export function SelectModal({
  activeModal,
  setActiveModal,
  mode = 0,
}: PropsSelectModal) {
  const KEY_INDEX = 'indexUser';
  const KEY_USER_STATUS_FLOW = 'userFlow';
  const KEY_ALL_USERS_STATUS_FLOW = 'statusFlowAll';
  // const { selectUser, dataSelectUser } = useFlow();
  const { toastErro } = useToast();
  const { createStorage, selectStorage, removeStorage } = useStorage();
  const { setQuestionariosInfo, setModalControl, declaracaoId, stateRandom } =
    useProvider();
  const [declaretion, setDeclaretion] = useState<any>();
  const [titularState, setTitularState] = useState('');
  const [userCompleteAll, setUserCompleteAll] = useState(false);
  const [dataSelectUser, setDataSelectUser] = useState<any>([]);
  const navigate = useNavigate();
  const { getReviewData } = useReviewDetails();

  const refreshQuestionarioInfo = async () => {
    try {
      const operation = await selectStorage('IMG');
      await getQuestionariosInfo(declaracaoId, operation!).then((res) => {
        setQuestionariosInfo(res?.data);
        setDataSelectUser(res?.data);
      });
    } catch (error) {
      console.log('refreshQuestionarioInfo: ', error);
    }
  };

  const setIndexUser = async (val: any) => {
    await createStorage(KEY_INDEX, val);
  };

  const verfifyAllStatus = (data: any) => {
    const filter = data?.every(
      (item: any) => item?.lastStep === 'ARQUIVOS_ENVIADOS'
    );
    setUserCompleteAll(filter);
  };

  const fetchReviewData = async () => {
    try {
      const operation = await selectStorage('IMG');
      const response = await getReviewData(declaracaoId, operation!);

      setDeclaretion(response);
      await createStorage(
        KEY_USER_STATUS_FLOW,
        JSON?.stringify(response?.declaracoes)
      );
      await createStorage(
        KEY_ALL_USERS_STATUS_FLOW,
        JSON?.stringify(response?.declaracoes)
      );
      await verifyTitula(response?.declaracoes);
      const userList = response?.declaracoes;
      const stateTitle = userList?.filter(
        (val: any) => val?.tipo === 'TITULAR'
      )!;

      verfifyAllStatus(userList);

      setTitularState(stateTitle[0]?.lastStep);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const refreshAll = async () => {
    await refreshQuestionarioInfo()
      .then(() => {
        // selectUser();
      })
      .catch((erro) => {
        console.log('deu erro: ', erro);
      });
    await fetchReviewData();
  };

  useEffect(() => {
    refreshAll();
  }, [activeModal, mode, stateRandom]);

  const verifyTitula = async (data: any) => {
    if (data) {
      const stateTitle = data?.filter((val: any) => val?.tipo === 'TITULAR');
      if (stateTitle[0]?.lastStep !== 'ARQUIVOS_ENVIADOS') {
        await createStorage('userIndexSelect', 0);
        setIndexUser(0);
      } else if (stateTitle[0]?.lastStep === 'ARQUIVOS_ENVIADOS') {
      }
    }
  };

  const selectNexStep = async (row: any, index: number) => {
    if (!validateStatusUser(row)) {
      setIndexUser(index);
      if (mode === 1 || index) {
        await createStorage('userIndexSelect', index);
        navigateFlow(`/RegistrarBeneficiary?renderer=${Math.random()}`);
      } else {
        directFlow(titularState);
      }
    } else {
      index >= 1
        ? toastErro('Selecione o titula primeiro para ser realizado.')
        : toastErro('Usuário já realizado a declaração de saúde');
    }
  };

  const selectHolderNexStep = async (row: any, index: number) => {
    console.log(validateStatusUser(row), index === 0);
    if (!validateStatusUser(row) && index === 0) {
      setIndexUser(index);
      await createStorage('userIndexSelect', index);
      directFlow(titularState);
    } else {
      toastErro('Selecione o titula primeiro para ser realizado.');
    }
  };

  const clearStorage = () => {
    removeStorage('ip');
    removeStorage('authToken');
    removeStorage('declaracaoId');
    removeStorage('questionariosInfo');
  };

  useEffect(() => {
    if (userCompleteAll) {
      navigate('/Finished');
      setModalControl(false);
      clearStorage();
    }
  }, [userCompleteAll, stateRandom]);

  const navigateFlow = (router: string) => {
    setActiveModal();
    navigate(router);
  };

  const directFlow = (flow: string) => {
    switch (flow) {
      case 'NONE':
      case 'LOGIN':
        console.log('LOGIN---');
        navigateFlow('/TermsUse');
        break;
      case 'CONFIRMOU_TERMOS_USO':
        navigateFlow('/ReviewDetails');
        console.log('CONFIRMOU_TERMOS_USO');
        break;
      case 'CONFIRMOU_DADOS':
        console.log('CONFIRMOU_DADOS');
        navigateFlow('/LetterBeneficiary');
        break;
      case 'CONFIRMOU_CARTA_ORIENTACAO':
        console.log('CONFIRMOU_CARTA_ORIENTACAO');
        navigateFlow('/RegistrarBeneficiary');
        break;
      case 'QUESTIONARIO_FINALIZADO':
        console.log('QUESTIONARIO_FINALIZADO');
        navigateFlow('/PhotoCapture?id=0');
        break;
      case 'ARQUIVOS_ENVIADOS':
        console.log('ARQUIVOS_ENVIADOS');

        break;
      default:
    }
  };

  const titleValidate = (titleSelect: boolean) => {
    return titleSelect
      ? 'Selecione o usuário para realizar a coletas das informações'
      : 'Vamos precisar coletar as informações do titular primeiramente';
  };

  const validateStatusUser = (row: any) => {
    return row?.temRubrica && row?.temSelfie && row?.questionarioFeito;
  };

  const filterHolder = () => {
    if (dataSelectUser) {
      const user = dataSelectUser[0];
      return validateStatusUser(user); // FALSE - COM PENDECIAS - TRUE - Tudo ok
    }
  };

  return (
    <Modal
      key={stateRandom}
      styles={{
        modal: { padding: 0, borderRadius: 16 },
      }}
      open={activeModal}
      onClose={setActiveModal}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      {!declaretion?.finalizadoTodos ? (
        <div className="xl:w-4/4 sm:w-4/4  rounded-md ">
          <div className="shadow-2xl rounded-md flex flex-col ">
            <div className="w-auto p-4 bg-blue-400 flex justify-center items-center rounded-t-md">
              <p className="text-center text-blue-50 font-medium text-lg pl-1 pr-1">
                {titleValidate(filterHolder())}
              </p>
            </div>
            <div className="w-full flex-row p-4">
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <HeaderTablet />
                  <TableBody key={stateRandom}>
                    {dataSelectUser?.map((row: any, index: number) => (
                      <>
                        {!filterHolder() ? (
                          <TableRow
                            key={row?.nome}
                            style={{
                              backgroundColor:
                                index >= 1 ? '#edecec' : 'inherit',
                              opacity: index >= 1 ? 0.5 : 1,
                            }}
                            onClick={() => selectHolderNexStep(row, index)}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row?.nome}
                            </TableCell>
                            <TableCell align="right" sx={display}>
                              {row?.cpf}
                            </TableCell>
                            <TableCell align="center" sx={display}>
                              {IconStatusValidate(row?.questionarioFeito)}
                            </TableCell>
                            <TableCell align="center">
                              {IconStatusValidatedEPENDENTE(
                                row?.temRubrica,
                                index
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {IconStatusValidatedEPENDENTE(
                                row?.temRubrica,
                                index
                              )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow
                            key={row?.nome}
                            style={{
                              backgroundColor: validateStatusUser(row)
                                ? '#edecec'
                                : 'inherit',
                              opacity: validateStatusUser(row) ? 0.5 : 1,
                            }}
                            onClick={() => selectNexStep(row, index)}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row?.nome}
                            </TableCell>
                            <TableCell align="right" sx={display}>
                              {row?.cpf}
                            </TableCell>
                            <TableCell align="center" sx={display}>
                              {IconStatusValidate(row?.questionarioFeito)}
                            </TableCell>
                            <TableCell align="center">
                              {IconStatusValidatedEPENDENTE(
                                row?.temSelfie,
                                index
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {IconStatusValidatedEPENDENTE(
                                row?.temRubrica,
                                index
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Finished />
        </>
      )}
    </Modal>
  );
}

const HeaderTablet = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Nome</TableCell>
        <TableCell align="center" sx={display}>
          CPF
        </TableCell>
        <TableCell align="center" sx={display}>
          PERGUNTAS
        </TableCell>
        <TableCell align="center">SELFIE</TableCell>
        <TableCell align="center">ASSINATURA</TableCell>
      </TableRow>
    </TableHead>
  );
};

const IconStatusValidate = (status: boolean) => {
  return (
    <>
      {status ? (
        <TaskAltIcon color="success" />
      ) : (
        <ErrorOutlineIcon color="error" />
      )}
    </>
  );
};

const IconStatusValidatedEPENDENTE = (status: boolean, index: number) => {
  if (index === 0) {
    return (
      <>
        {status ? (
          <TaskAltIcon color="success" />
        ) : (
          <ErrorOutlineIcon color="error" />
        )}
      </>
    );
  } else {
    return <>--</>;
  }
};
