import { useCallback } from 'react';

export function useStorage() {
  const selectStorage = useCallback((key: string) => {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      console.log('selectStorage: ', error);
    }
  }, []);

  const createStorage = useCallback((name: string, data: any) => {
    try {
      return localStorage.setItem(name, data);
    } catch (error) {
      console.log('createStorage: ', error);
    }
  }, []);

  const removeStorage = useCallback((name: string) => {
    try {
      return localStorage.removeItem(name);
    } catch (error) {
      console.log('removeStorage: ', error);
    }
  }, []);

  return {
    selectStorage,
    createStorage,
    removeStorage,
  };
}
