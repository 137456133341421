import { useCallback } from 'react';

export const useFormatter = () => {
  const realFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formatMoney = (amount?: number) => {
    return amount ? realFormatter.format(amount) : realFormatter.format(0);
  };

  const formatedTest = (date: string) => {
    var data = new Date(date);
    return data?.toLocaleString('pt-BR', {
      timeZoneName: 'longOffset',
      timeZone: 'America/Rio_Branco',
    });
  };

  const getDateComponents = (dateString: string) => {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    const formattedMonth = month.toString().padStart(2, '0');

    return `${day + '/' + formattedMonth + '/' + year}`;
  };

  const formatDateString = (date?: string) => {
    return date && formatDate(new Date(date));
  };
  const formatDate = (date: Date) => {
    return date.toLocaleDateString('pt-br');
  };
  const formatCpf = (cpf?: string) => {
    if (cpf) {
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    return cpf;
  };
  const formatTelefone = (telefone?: string) => {
    if (telefone) {
      if (telefone.length === 11) {
        return telefone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
      } else if (telefone.length === 10) {
        return telefone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
      }
    }
    return telefone;
  };
  const normalizeInputDate = useCallback((value: String | undefined) => {
    if (!value) return '';

    return value
      .replace(/[\D]/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{4})$/, '$1');
  }, []);

  const normalizeCpfNumber = useCallback((value: String | undefined) => {
    if (!value) return '';

    return value
      .replace(/[\D]/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }, []);

  const convertDate = (dateStr: string) => {
    if (!dateStr) return '#';
    const [year, month, day] = dateStr.split('/');
    return `${day}/${month}/${year}`;
  };

  return {
    realFormatter,
    formatDate,
    formatDateString,
    formatMoney,
    formatCpf,
    formatTelefone,
    normalizeInputDate,
    normalizeCpfNumber,
    convertDate,
    formatedTest,
    getDateComponents,
  };
};
