import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStorage } from 'src/global/hooks/useStorage';
import { useProvider } from 'src/provider/provider';
import { useToast } from '../../global/toast/useToast';
import {
  aceitarTermosUso,
  getQuestionariosInfo,
} from '../../services/services';

export function useTermUsers() {
  const [checked, setChecked] = useState(false);

  const { toastErro } = useToast();
  const { createStorage, selectStorage } = useStorage();
  const {
    ipPublic,
    declaracaoId,
    setLoadingValue,
    setQuestionariosInfo,
    questionariosInfo,
  } = useProvider();
  const navigate = useNavigate();

  const directRouter = '/ReviewDetails';

  const handleCheckboxChange = (event: any) => {
    setChecked(event.target.checked);
  };

  const ConfirmTerm = useCallback(async () => {
    setLoadingValue(true);
    const operation = await selectStorage('IMG');
    aceitarTermosUso(ipPublic, 1, declaracaoId, operation!)
      .then(() => {
        if (!questionariosInfo) {
          getQuestionariosInfo(declaracaoId, operation!).then((res) => {
            setQuestionariosInfo(res.data);
          });
        }
        navigate(directRouter);
      })
      .catch((erro: any) => {
        console.log(erro);
        toastErro(
          `Ocorreu algum erro, entre em contato com administrador. Erro: ${erro?.response?.data?.errors?.message} `
        );
      })
      .finally(() => {
        setLoadingValue(false);
      });
  }, [
    ipPublic,
    toastErro,
    navigate,
    setLoadingValue,
    declaracaoId,
    createStorage,
    setQuestionariosInfo,
    questionariosInfo,
  ]);

  return {
    checked,
    setChecked,
    handleCheckboxChange,
    ConfirmTerm,
  };
}
