export const Finished = () => {
  // let [searchParams, _] = useSearchParams();

  // const navigate = useNavigate();
  // useEffect(() => {
  //   const declaracaoIdLink = searchParams.get('drc');
  //   setTimeout(() => {
  //     navigate(`/?drc=${declaracaoIdLink}`);
  //   }, 5000);
  // }, []);
  return (
    <div className="w-screen h-screen bg-gray-100 flex justify-center items-center">
      <div className=" bg-white rounded-md flex flex-col m-5 p-10 w-[500px] items-center">
        <img
          src={require('../../assets/sucesso.png')}
          style={{ width: 200, marginBottom: 50 }}
        />
        <h2 className="font-bold text-2xl text-center">
          Parabéns! Sua declaração de saúde foi preenchida com sucesso e será
          encaminhada para o setor de cadastro.
        </h2>
        {/* <p>Essa página será removida em alguns instantes...</p> */}
      </div>
    </div>
  );
};
