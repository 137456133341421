import { Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PropostaInfoDetailsResponseDTO } from 'src/dtos/propostaInfoDetailsResponseDTO';
import { useStorage } from 'src/global/hooks/useStorage';
import { useFormatter } from 'src/global/utils/useFormatter';
import { useProvider } from 'src/provider/provider';
import { StepForm } from '../../components/stepForm';
import { useLetterBeneficiary } from '../letterBeneficiary/useLetterBeneficiary';
import { useReviewDetails } from './useReviewDetails';

export function ReviewDetails() {
  const navigate = useNavigate();
  const { getReviewData } = useReviewDetails();
  const { ConfirmTermDataList } = useLetterBeneficiary();
  const { formatMoney, formatCpf, formatTelefone, getDateComponents } =
    useFormatter();
  const { setLoadingValue, declaracaoId, ipPublic } = useProvider();
  const [data, setData] = useState<PropostaInfoDetailsResponseDTO | null>(null);
  const { selectStorage } = useStorage();

  useEffect(() => {
    const fetchReviewData = async () => {
      setLoadingValue(true);
      const operation = await selectStorage('IMG');
      const response = await getReviewData(declaracaoId, operation!);
      setData(response!);
      setLoadingValue(false);
    };

    if (declaracaoId && ipPublic && !data) {
      fetchReviewData();
    }
  }, []);

  const selectUser = async () => {
    setLoadingValue(true);
    await ConfirmTermDataList()
      .then(() => {
        navigate('/LetterBeneficiary');
      })
      .catch((erro) => {
        console.log(erro);
      });
    setLoadingValue(false);
  };

  if (!data) {
    // poderiamos colocar um skeleton aqui
    return <></>;
  }
  return (
    <div className="w-screen h-screen pt-9">
      <StepForm itemSelect={1} />

      <div className="shadow-2xl rounded-md ml-6 mr-6 flex flex-col mt-6 ">
        <div className="w-auto h-12 bg-blue-400 rounded-t-lg flex justify-center items-center">
          <p className="text-center text-blue-50 font-bold text-lg">Titular</p>
        </div>
        <div className="p-6">
          <TextField
            disabled
            label="Nome"
            sx={{ marginTop: 2 }}
            defaultValue={data?.dadosTitular?.nome}
            fullWidth
          />
          <div className="w-full flex flex-wrap">
            <div className="w-full flex flex-row sm:w-4/4 md:w-1/2 lg:w-1/2 xl:w-4/4 2xl:w-1/2 xl:pr-1 lg:pr-1 md:pr-1">
              <TextField
                disabled
                label="CPF"
                sx={{ marginTop: 2 }}
                defaultValue={formatCpf(data?.dadosTitular?.cpf)}
                fullWidth
              />
            </div>
            <div className="w-full flex flex-row sm:w-4/4 md:w-1/2 lg:w-1/2 xl:w-4/4 2xl:w-1/2 xl:pl-1 lg:pl-1 md:pl-1">
              <TextField
                disabled
                label="Data de Nascimento"
                sx={{ marginTop: 2 }}
                defaultValue={getDateComponents(
                  data?.dadosTitular?.dtNascimento
                )}
                fullWidth
              />
            </div>
          </div>

          <TextField
            disabled
            label="Endereço"
            sx={{ marginTop: 2 }}
            defaultValue={data?.dadosTitular?.endereco}
            fullWidth
          />
          <div className="w-full flex flex-wrap">
            <div className="w-full flex flex-row sm:w-4/4 md:w-1/2 lg:w-1/2 xl:w-4/4 2xl:w-1/2 xl:pr-1 lg:pr-1 md:pr-1">
              <TextField
                disabled
                fullWidth
                label="Telefone"
                sx={{ marginTop: 2 }}
                defaultValue={formatTelefone(data?.dadosTitular?.telefone)}
              />
            </div>
            <div className="w-full flex flex-row sm:w-4/4 md:w-1/2 lg:w-1/2 xl:w-4/4 2xl:w-1/2 xl:pl-1 lg:pl-1 md:pl-1">
              <TextField
                disabled
                fullWidth
                label="Celular"
                sx={{ marginTop: 2 }}
                defaultValue={formatTelefone(data?.dadosTitular?.celular)}
              />
            </div>
          </div>
          <div className="w-full flex flex-wrap">
            <div className="w-full flex flex-row sm:w-4/4 md:w-1/2 lg:w-1/2 xl:w-4/4 2xl:w-1/2 xl:pr-1 lg:pr-1 md:pr-1">
              <TextField
                disabled
                label="Sexo"
                sx={{ marginTop: 2 }}
                fullWidth
                defaultValue={
                  data?.dadosTitular?.sexo === 'F' ? 'Feminino' : 'Masculino'
                }
              />
            </div>

            <div className="w-full flex flex-row sm:w-4/4 md:w-1/2 lg:w-1/2 xl:w-4/4 2xl:w-1/2 xl:pl-1 lg:pl-1 md:pl-1">
              <TextField
                disabled
                label="Email"
                fullWidth
                sx={{ marginTop: 2 }}
                defaultValue={data?.dadosTitular?.email}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="shadow-2xl rounded-md m-6 flex flex-col">
        <div className="w-auto h-12 bg-blue-400 rounded-t-lg flex justify-center items-center">
          <p className="text-center text-blue-50 font-bold text-lg">
            Dados Financeiros
          </p>
        </div>
        <div className="p-6">
          <TextField
            disabled
            label="Nome"
            sx={{ marginTop: 2 }}
            defaultValue={data?.financeiro[0]?.nome}
            fullWidth
          />

          <div className="w-full flex flex-wrap">
            <div className="w-full flex flex-row sm:w-4/4 md:w-1/2 lg:w-1/2 xl:w-4/4 2xl:w-1/2 xl:pr-1 lg:pr-1 md:pr-1">
              <TextField
                disabled
                defaultValue={getDateComponents(
                  data?.financeiro[0]?.dtNascimento
                )}
                label="Data de Nascimento"
                sx={{ marginTop: 2 }}
                fullWidth
              />
            </div>
            <div className="w-full flex flex-row sm:w-4/4 md:w-1/2 lg:w-1/2 xl:w-4/4 2xl:w-1/2 xl:pl-1 lg:pl-1 md:pl-1">
              <TextField
                disabled
                label="Plano"
                sx={{ marginTop: 2 }}
                defaultValue={data?.financeiro[0]?.plano}
                fullWidth
              />
            </div>
          </div>

          <TextField
            disabled
            label="Valor"
            sx={{ marginTop: 2 }}
            defaultValue={formatMoney(data?.financeiro[0]?.valor)}
            fullWidth
          />
        </div>
      </div>

      <div className="shadow-2xl rounded-md m-6 flex flex-col">
        <div className="w-auto h-12 bg-blue-400 rounded-t-lg flex justify-center items-center">
          <p className="text-center text-blue-50 font-bold text-lg">
            Dependentes
          </p>
        </div>
        {data.financeiro.map((item, index) => {
          if (![0, 1].includes(index)) {
            return (
              <div className="p-6" key={index + item.cpf}>
                <div className="w-full flex flex-wrap">
                  <div className="w-full flex flex-row sm:w-4/4 md:w-1/2 lg:w-1/2 xl:w-4/4 2xl:w-1/2 xl:pr-1 lg:pr-1 md:pr-1">
                    <TextField
                      disabled
                      label="Nome"
                      sx={{ marginTop: 2 }}
                      defaultValue={item?.nome}
                      fullWidth
                    />
                  </div>
                  <div className="w-full flex flex-row sm:w-4/4 md:w-1/2 lg:w-1/2 xl:w-4/4 2xl:w-1/2 xl:pr-1 lg:pr-1 md:pr-1">
                    <TextField
                      disabled
                      label="CPF"
                      sx={{ marginTop: 2 }}
                      defaultValue={formatCpf(item?.cpf)}
                      fullWidth
                    />
                  </div>
                </div>

                <div className="w-full flex flex-wrap">
                  <div className="w-full flex flex-row sm:w-4/4 md:w-1/2 lg:w-1/2 xl:w-4/4 2xl:w-1/2 xl:pr-1 lg:pr-1 md:pr-1">
                    <TextField
                      disabled
                      defaultValue={getDateComponents(item?.dtNascimento)}
                      label="Data de Nascimento"
                      sx={{ marginTop: 2 }}
                      fullWidth
                    />
                  </div>
                  <div className="w-full flex flex-row sm:w-4/4 md:w-1/2 lg:w-1/2 xl:w-4/4 2xl:w-1/2 xl:pl-1 lg:pl-1 md:pl-1">
                    <TextField
                      disabled
                      label="Plano"
                      sx={{ marginTop: 2 }}
                      defaultValue={item?.plano}
                      fullWidth
                    />
                  </div>
                </div>

                <TextField
                  disabled
                  label="Valor"
                  sx={{ marginTop: 2 }}
                  defaultValue={formatMoney(item?.valor)}
                  fullWidth
                />
              </div>
            );
          }
        })}
      </div>

      <div className="shadow-2xl rounded-md m-6 flex flex-col">
        <div className="w-auto h-12 bg-blue-400 rounded-t-lg flex justify-center items-center">
          <p className="text-center text-blue-50 font-bold text-lg">
            Taxa Associativa
          </p>
        </div>
        <div className="w-full flex flex-wrap p-6">
          <TextField
            disabled
            label="Valor"
            sx={{ marginTop: 2 }}
            defaultValue={formatMoney(data?.financeiro[1]?.valor)}
            fullWidth
          />
        </div>
      </div>
      <div className="shadow-2xl rounded-md ml-6 mr-6 flex flex-col mt-6 ">
        <div className="w-auto h-12 bg-blue-400 rounded flex justify-center items-center">
          <p className="text-center text-blue-50 font-bold text-lg">
            Valor total: {formatMoney(data?.totalProposta)}
          </p>
        </div>
      </div>
      <div className="p-4">
        {/* <Button variant="contained" fullWidth sx={{margin: 1}}>Login</Button> */}
        <Button variant="contained" fullWidth onClick={() => selectUser()}>
          Seguir
        </Button>
      </div>
    </div>
  );
}
