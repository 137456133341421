import { useCallback } from 'react';
import { useStorage } from 'src/global/hooks/useStorage';
import { useProvider } from 'src/provider/provider';
import { saveDigitalFiles } from 'src/services/services';

export const useDigitalFiles = () => {
  const { setLoadingValue, declaracaoId } = useProvider();
  const { createStorage, selectStorage } = useStorage();

  const saveFiles = useCallback(
    async (
      questionarioId: number,
      formData: FormData,
      pessoaId: number,
      operationId: string
    ) => {
      setLoadingValue(true);
      const operation = await selectStorage('IMG');
      return saveDigitalFiles(
        Number(declaracaoId),
        questionarioId,
        formData,
        pessoaId,
        operationId,
        operation!
      )
        .then((res) => res)
        .catch((erro: any) => {
          console.log('err0:: ', erro);
        })
        .finally(() => {
          setLoadingValue(false);
        });
    },
    [setLoadingValue, declaracaoId]
  );
  return {
    saveFiles,
  };
};
