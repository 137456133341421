import { Checkbox } from '@mui/material';
import Button from '@mui/material/Button';
import { StepForm } from '../../components/stepForm';
import { useLetterBeneficiary } from './useLetterBeneficiary';

export function LetterBeneficiary() {
  const { ConfirmTerm, checked, handleCheckboxChange } = useLetterBeneficiary();

  return (
    <div className="w-screen h-screen p-10 ">
      <StepForm itemSelect={2} />
      <div className="text-center mb-2 italic">
        Carta de Orientação de Saúde
      </div>
      <div className="shadow  p-5 h-3/4 overflow-y-auto">
        <p className="mt-5 text-justify ">
          CARTA DE ORIENTAÇÃO AO BENEFICIÁRIO
          <br />A Agência Nacional de Saúde Suplementar (ANS), instituição que
          regula a atividades das operadoras de planos privados de assistência à
          saúde, e tem como missão defender o interesse público vem, por meio
          desta, prestar informações para o preenchimento da DECLARAÇÃO DE SAÚDE
        </p>

        <p className="mt-5 text-justify ">O QUE É A DECLARAÇÃO DE SAÚDE?</p>
        <p className="mt-5 text-justify text-sm italic font-extralight">
          É o formulário que acompanha o Contrato do Plano de Saúde, onde o
          beneficiørio ou seu representante legal deverá informar as doenças ou
          lesões preexistentes que saiba ser portador ou sofredor no momento da
          contratação do plano. Para o seu preenchimento, o beneficiário tem o
          direito de ser orientado, gratuitamente, por um médico
          credenciado/referenciado pela operadora. Se optar por um profissional
          de sua livre escolha, assumirá o custo desta opção. Portanto, se o
          beneficiário (você) toma medicamentos regularmente, consulta médicos
          por problema de saúde do qual conhece o diagnóstico, fez qualquer
          exame que identificou alguma doença ou lesão, esteve internado ou
          submeteu-se a alguma cirurgia, DEVE DECLARAR ESTA DOENÇA OU LESÃO.
        </p>

        <p className="mt-5 text-justify">
          AO DECLARAR AS DOENÇAS E/OU LESÕES QUE O BENEFICIÁRIO SAIBA SER
          PORTADOR NO MOMENTO DA CONTRATAÇÃO:
        </p>
        <p className="mt-5 text-justify text-sm italic font-extralight">
          • A operadora NÃO poderá impedi-lo de contratar o plano de saúde. Caso
          isto ocorra, encaminhe a denúncia à ANS.
        </p>

        <p className="mt-5 text-justify text-sm italic font-extralight">
          • A operadora deverá oferecer: cobertura total ou COBERTURA PARCIAL
          TEMPORÁRIA (CPT), podendo ainda oferecer o Agravo, que é um acréscimo
          no valor da mensalidade, pago ao plano privado de assistência à saúde,
          para que se possa utilizar toda a cobertura contratada, após os prazos
          de carências contratuais.
        </p>

        <p className="mt-5 text-justify text-sm italic font-extralight">
          • No caso de CPT, haverá restrição de cobertura para cirurgias, leitos
          de alta tecnologia (UTI, unidade coronariana ou neonatal) e
          procedimentos de alta complexidade – PAC (tomografia, resson¿ncia,
          etc.*) EXCLUSIVAMENTE relacionados à doença ou lesão declarada, até 24
          meses, contados desde a assinatura do contrato. Após o perÀodo møximo
          de 24 meses da assinatura contratual, a cobertura passará a ser
          integral de acordo com o plano contratado.
        </p>

        <p className="mt-5 text-justify text-sm italic font-extralight">
          • NÃO haverá restrição de cobertura para consultas médicas,
          internações não cirúrgicas, exames e procedimentos que não sejam de
          alta complexidade, mesmo que relacionados à doença ou lesão
          preexistente declarada, desde que cumpridos os prazos de carências
          estabelecidas no contrato.
        </p>

        <p className="mt-5 text-justify text-sm italic font-extralight">
          • NÃO haverá restrição de cobertura para consultas médicas,
          internações não cirúrgicas, exames e procedimentos que não sejam de
          alta complexidade, mesmo que relacionados à doença ou lesão
          preexistente declarada, desde que cumpridos os prazos de carências
          estabelecidas no contrato. • Não caberá alegação posterior de omissão
          de informação na Declaração de Saúde por parte da operadora para esta
          doença ou lesão.
        </p>

        <p className="mt-5 text-justify text-sm italic">
          AO NÃO DECLARAR AS DOENÇAS E/OU LESÕES QUE O BENEFICIÁRIO SAIBA SER
          PORTADOR NO MOMENTO DA CONTRATAÇÃO:
        </p>

        <p className="mt-5 text-justify text-sm italic font-extralight">
          • A operadora poderá suspeitar de omissão de informação e, neste caso,
          deverá comunicar imediatamente ao beneficiørio, podendo oferecer CPT,
          ou solicitar abertura de processo administrativo junto à ANS,
          denunciando a omissão da informação.
        </p>

        <p className="mt-5 text-justify text-sm italic font-extralight">
          • Comprovada a omissão de informação pelo beneficiário, a operadora
          poderá RESCINDIR o contrato por FRAUDE e responsabilizá-lo pelos
          procedimentos referentes a doença ou lesão não declarada.
        </p>

        <p className="mt-5 text-justify text-sm italic font-extralight">
          • Até o julgamento final do processo pela ANS, NÃO poderá ocorrer
          suspensão do atendimento nem rescisão do contrato. Caso isto ocorra,
          encaminhe a denúncia à ANS.
        </p>
        <p className="mt-5 text-justify text-sm italic ">
          ATENÇÃO! Se a operadora oferecer redução ou isensão de carência, isto
          não significa que dará cobertura assistencial para as doenças ou
          lesões que o beneficiário saiba ter no momento da assinatura
          contratual. Cobertura Parcial Temporária - CPT - NÃO é carência!
          Portanto, o beneficiário não deve deixar de informar se possui alguma
          doen≈a ou lesão ao preencher a Declaração de Saúde!
        </p>
        <p className="mt-5 text-justify text-sm italic ">
          * Para consultar a lista completa de procedimentos de alta
          complexidade – PAC, acesse o Rol de Procedimentos e Eventos em Saÿde
          da ANS no endereço eletrónico: www.ans.gov.br - Perfil Beneficiário.
          Em caso de dúvidas, entre em contato com a ANS pelo telefone
          0800-701-9656 ou consulte a página da ANS - www.ans.gov.br - Perfil
          Beneficiário.
        </p>
      </div>
      <div className="flex flex-row items-center mt-3">
        <Checkbox onChange={handleCheckboxChange} />
        <div> Estou ciente.</div>
      </div>
      <Button
        variant="contained"
        fullWidth
        disabled={!checked}
        onClick={() => {
          ConfirmTerm();
        }}
      >
        Seguir
      </Button>
    </div>
  );
}
