import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box } from '@mui/material';

interface PropsStep {
  itemSelect: number;
}

export function StepForm({ itemSelect }: PropsStep) {
  const steps = [
    'Contrato de termos de uso.',
    'Confirmação de dados.',
    'Carta de orientação',
    'Questionário',
    'Coleta de arquivos',
  ];
  return (
    <Box sx={{ width: '100%', paddingBottom: 3 }}>
      <Stepper activeStep={itemSelect} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>
              <div className="hidden sm:block">{label}</div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
